
body {
  background-color: #f2f2f2;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #434445;

    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
}

/* Titles */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: #434445;
}

h1 {
	font-size: 40px;
}

h3 {
	color: #434445;
	font-weight: 400;
}

h4 {
	color: #434445;
	font-weight: 400;
	font-size: 20px;
}

/* Paragraph & Typographic */
p {
    line-height: 28px;
    margin-bottom: 25px;
    font-size: 16px;
}

.centered {
    text-align: center;
}

/* Links */
a {
    color: #073350;
    word-wrap: break-word;

    -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
    -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
    -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
    -o-transition: color 0.1s ease-in, background 0.1s ease-in;
    transition: color 0.1s ease-in, background 0.1s ease-in;
}

a:hover,
a:focus {
    color: #7b7b7b;
    text-decoration: none;
    outline: 0;
}

a:before,
a:after {
    -webkit-transition: color 0.1s ease-in, background 0.1s ease-in;
    -moz-transition: color 0.1s ease-in, background 0.1s ease-in;
    -ms-transition: color 0.1s ease-in, background 0.1s ease-in;
    -o-transition: color 0.1s ease-in, background 0.1s ease-in;
    transition: color 0.1s ease-in, background 0.1s ease-in;
}

 hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.navbar-default {
	background-color: #3498db;
	border-color: transparent;
}

.navbar-default .navbar-brand {
  color: white;
}

.navbar-default .navbar-nav > li > a {
	color: white;
}

/* Helpers */

.mt {
	margin-top: 40px;
	margin-bottom: 40px;
}

.form-control {
	height: 42px;
	font-size: 18px;
  width: 280px;
  margin-top:0px;
}

.form-check {
  box-shadow: none !important;
}

.form-control-small-table {
  height: 32px;
  font-size: 14px;
}

i {
	margin: 8px;
	color: #3498db; 
}

.icon-help {
  color: darkblue;
  cursor: pointer;
  margin-left: 15px;
  font-size: 13px;

}


/* HeaderWrap */
#headerwrap {
	/* background: url(../img/bg01.jpg) no-repeat center top; */
	background-color: #3498db;
	margin-top: -20px;
	padding-top:200px;
	background-attachment: relative;
	background-position: center center;
	min-height: 650px;
	width: 100%;
	
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#headerwrap h1 {
	margin-top: 60px;
	margin-bottom: 15px;
	color: white;
	font-size: 45px;
	font-weight: 300;
	letter-spacing: 1px;
}

.form-control {
  width: 100%;
}

.full-control {
  width:100%;
}

.navbar-nav li a {
  cursor: pointer;
}

.react-datepicker__input-container {
  width: 100%;
}

.banner-responsive {
  max-width: 100%;
}

.help-icon{
  cursor: pointer;
}

.help-container {
  display: block;
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  background-color: #fffde7;
  border-radius: 3px;
}

.chart-title {
  font-weight: bold;
  font-size: 18px;
}

.navbar {
  background-color: #073350;
}

.nav-link {
  color: white !important;
  font-weight: bold;
}

.btn-primary {
  background-color: #073350;
}

.list-row {
  border-bottom-color: #777;
  border-bottom-width: 2px;
  margin-bottom: 5px;
}

.list-row-header {
  font-weight: bold;
  text-decoration: underline;
}

/* icons */
.icon {
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
}

.icon-primary {
  color: #073350;
}

.icon-danger {
  color: #dc3545!important;
}

.sort-icon {
  font-size: 10px;
  margin-left: 7px;
}

.cursor-link{
  cursor: pointer;
}

.fake-link {
  cursor: pointer;
  color: #073350;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput input {
  display: block;
  width: 100%;
  height: 42px;
  margin-top: 0px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.activity-list-card{
  margin-bottom: 10px;
}

.modal-94 {
  min-width: 94%;
}

.modal-50 {
  min-width: 50%;
}

.text-severity-normal {
  color: #000000;
  font-weight: bold;
}

.text-severity-severe {
  color: #de8a1d;
  font-weight: bold;
}

.text-severity-critical {
  color: #8f0700;
  font-weight: bold;
}

.alert-large {
  width: 400px;
}

.schedule-instance-can-register {
  background-color: #265985;
  color: #ffffff;
}

.schedule-instance-cannot-register {
  background-color: #777;
  color: #ffffff;
}

.schedule-instance-is-registered {
  background-color: #de8a1d;
  color: #ffffff;
}

.schedule-instance-is-full {
  background-color: #585654;
  color: #ffffff;
}

.schedule-instance-is-available {
  background-color: #058f28;
  color: #ffffff;
}

.schedule-instance-is-pending {
  background-color: #ffffff;
  color: #000000;
}

.calendar-legend {
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  border: thin solid black;
}

.rbc-toolbar-label {
  font-weight: bold;
  font-size: 18px;
  text-decoration: underline;
}

.DayPickerInput input {
  font-size: 18px;
}

.capitalize {
  text-transform: capitalize;
}

/* STRIPE */
input, .StripeElement {
  display: block;
  width: 100%;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
}

label.stripe-label {
  width: 100%;
}

.react-add-to-calendar__dropdown ul {
  padding-left: 4px;
}

.member-highlighted{
  background-color: #ecf1b8;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}

.schedule-template-calendar-holder .rbc-calendar .rbc-time-header {
  display:none;
}

.schedule-template-calendar-holder .rbc-calendar .rbc-toolbar {
  display: none;
}